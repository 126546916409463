import axios from 'axios';
import { TRANSITIONS_MS_URL } from '../../constants/api.js';
import { getAccessToken } from './auth.js';
import logger from '@frello-tech/front-utils/dist/utils/logger.js';

export const fetchTransition = async (authData, args) => {
  let {
    sectionId,
    moduleId,
    evaluationSessionId,
    surveyId,
    isModuleDoneFreely
  } = args;

  moduleId = parseInt(moduleId, 10);
  sectionId = parseInt(sectionId, 10);
  evaluationSessionId = parseInt(evaluationSessionId, 10) || undefined;

  if (sectionId < 0) {
    sectionId = undefined;
  }

  const url = TRANSITIONS_MS_URL;

  const data = JSON.stringify({
    query: `
      query Transition(
        $moduleId: Int
        $evaluationSessionId: Int
        $sectionId: Int
        $surveyId: Int
        $isModuleDoneFreely: Boolean
      ) {
        transition(
          moduleId: $moduleId
          evaluationSessionId: $evaluationSessionId
          sectionId: $sectionId
          surveyId: $surveyId
          isModuleDoneFreely: $isModuleDoneFreely
        ) {
          type
          continueOptions {
            type
            moduleId
            isInHomework
          }
          currentModuleStatus
          sectionData {
            validatedModulesCount
            modulesData {
              moduleId
              levelName
              order
              result
              status
              terminated
              validated
            }
            sessionId
            progress
            evaluationSession {
              status
              nextSection {
                id
                status
                skillType {
                  name
                }
              }
            }
            skillType {
              name
            }
          }
          xpEarnedByLearner
          timeSpent
          isFirstSurveyOfTheDay
        }
      }
    `,
    variables: {
      sectionId,
      moduleId,
      evaluationSessionId,
      surveyId,
      isModuleDoneFreely
    }
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  const { data: res } = await axios(axiosConfig);

  return res.data.transition;
};

export const uploadImageForSharing = async ({ authData, imageBase64 }) => {
  const url = TRANSITIONS_MS_URL;

  const data = JSON.stringify({
    query: `mutation uploadImageForSharing($imageBase64: String!) {
              uploadImageForSharing(imageBase64: $imageBase64)
            }`,
    variables: {
      imageBase64
    }
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  try {
    const { data: res } = await axios(axiosConfig);
    return res.data.uploadImageForSharing;
  } catch (error) {
    logger.error(error);
  }
};
