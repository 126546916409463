import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getCurrentUserFromMS } from '../services/api/user';
import * as Sentry from '@sentry/browser';
import { isMixPanelLoaded, mixpanel, analyticsTrack } from '../services/analytics';
import logger from '@frello-tech/front-utils/dist/utils/logger';
import { FETCH_CORRECTION_AI_ACCESS, FETCH_CORRECTION_AI_ACCESS_SUCCESS, LEARNER_BUG_REPORT, USER_LOGIN_SUCCESS } from '../actions/index';
import { getAuthData, selectCurrentClassroomId } from '../selectors';
import { getSchoolSettings } from '../services/api/school';

function * flow () {
  yield getCurrentUser();
  yield takeEvery(LEARNER_BUG_REPORT, learnerBugReport);
  yield takeEvery(FETCH_CORRECTION_AI_ACCESS, fetchCorrectionAiAccess);
}

function * getCurrentUser () {
  try {
    const authData = yield select(state => ({
      accessToken: state.user.accessToken,
      userAgent: state.user.agent
    }));
    const user = yield getCurrentUserFromMS({ authData });
    if (user) {
      yield put({ type: USER_LOGIN_SUCCESS, payload: user });
      isMixPanelLoaded && mixpanel.identify(user.id);
      isMixPanelLoaded && mixpanel.set({
        $created: user?.inscriptionDate,
        $type: user?.type
      });

      Sentry.setUser({
        id: user?.id,
        roles: user?.roles
      });
    }
  } catch (e) {
    logger.error('getCurrentUser error', e);
  }
}

function * learnerBugReport ({ payload }) {
  try {
    analyticsTrack('A signalé un bug', payload.data);
    logger.log('learnerReportSuccess', { extra: payload.data });
  } catch (error) {
    logger.error('learnerReportFailure', error);
  }
}

function * fetchCorrectionAiAccess () {
  try {
    const authData = yield select(getAuthData);
    const classroomId = yield select(selectCurrentClassroomId);
    if (!classroomId) {
      return;
    }
    const correctionAiAccessData = yield call(getSchoolSettings, { authData, classroomId });
    yield put({
      type: FETCH_CORRECTION_AI_ACCESS_SUCCESS,
      payload: correctionAiAccessData.correctionAiAccess
    });
  } catch (e) {
    logger.error(e);
  }
}

export default flow;
